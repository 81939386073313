const PageTitle = ({ title, children }) => {
  return (
    <div className="flex justify-between mb-7">
      <div className="text-2xl md:text-4xl font-medium capitalize">{title}</div>
      <div>{children}</div>
    </div>
  );
};

export default PageTitle;
