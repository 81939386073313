import axios from "axios";
import { getRefreshToken, setLocalStorage } from "./helpers";

class Http {
  constructor() {
    this.axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_API,
      timeout: 60000,
    });
    this.axiosInstance.interceptors.request.use(function (config) {
      const { access_token } =
        JSON.parse(localStorage.getItem(process.env.REACT_APP_APP_NAME)) || {};
      config.headers.Authorization = access_token
        ? `Bearer ${access_token}`
        : null;
      return config;
    });
    this.axiosInstance.interceptors.response.use(
      function (response) {
        return response.data;
      },
      async (error) => {
        const originalRequest = error.config;

        if (!error?.response) {
          return Promise.reject(error);
        }

        const {
          response: { status = null },
        } = error;

        switch (status) {
          case 401:
            // refresh token
            const res = await this.refresh();

            if (res?.status === 200 && res.data.error === false) {
              originalRequest.headers["Authorization"] =
                `Bearer ${res.data.access_token}`;
              return axios.request(originalRequest);
            } else {
              window.location = "/";
            }
            break;
          default:
            return Promise.reject(error.response);
        }
      },
    );
  }
  refresh = async () => {
    const res = await axios.post(`${process.env.REACT_APP_API}/auth/refresh`, {
      refresh_token: getRefreshToken(),
    });
    if (res.status === 200 && res.data.error === false) {
      const data = {
        access_token: res.data.access_token,
        refresh_token: res.data.refresh_token,
      };
      setLocalStorage(data);
    } else {
      window.location = "/";
    }
    return res;
  };
  get = (url, query, option) => {
    return this.axiosInstance.get(url, { params: query, ...option });
  };
  post = (url, payload, option) => {
    return this.axiosInstance.post(url, payload, option);
  };
  put = (url, payload, option) => {
    return this.axiosInstance.put(url, payload, option);
  };
  delete = (url, payload, option) => {
    return this.axiosInstance.delete(url, { data: payload, ...option });
  };
  postFiles = (url, payload, option) => {
    let headers = option?.headers;

    return this.axiosInstance.post(url, payload, {
      ...option,
      headers: {
        "Content-Type": "multipart/form-data",
        ...headers,
      },
    });
  };
}

export default new Http();
