import { useEffect } from "react";
import {
  createBrowserRouter,
  RouterProvider,
  Outlet,
  Navigate,
} from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import muiTheme from "./style/muiTheme";
import ToastContainer from "./components/common/Toast";
import { getAccessToken } from "./utils/helpers";
import { useUserStore } from "./stores";

import Login from "./pages/common/Login";
import Exercise from "./pages/Exercise";
import Vocabulary from "./pages/Vocabulary";
import VocabExercise from "./pages/VocabExercise";
import History from "./pages/History";
import HistoryDetail from "./pages/HistoryDetail";
import Layout from "./components/common/Layout";
import Profile from "./pages/common/Profile";
import Subscription from "./pages/profile/Subscription";
import CreditHistory from "./pages/profile/CreditHistory";
import Shop from "./pages/Shop";
import MyCart from "./pages/profile/MyCart";
import Register from "./pages/common/Register";
import EditExercise from "./components/exercise/EditExercise";
import Results from "./components/exercise/Results";
import Payment from "./components/common/Payment";
import TopUp from "./pages/profile/Topup";

import ForgotPassword from "./pages/common/ForgotPassword";
import ResetPassword from "./pages/common/ResetPassword";

import INSE from "./pages/INSE";

import i18n from "./config/i18n";

function App() {
  useEffect(() => {
    i18n.changeLanguage("zht");
  }, []);

  return (
    <ThemeProvider theme={muiTheme}>
      <RouterProvider router={router} />
      <ToastContainer />
    </ThemeProvider>
  );
}

const PrivateRoutes = () => {
  const userStore = useUserStore();
  const isLogin = !!getAccessToken();

  useEffect(() => {
    if (isLogin) {
      userStore.getMe();
      i18n.changeLanguage(userStore.user?.lang || "zht");
    }
  }, [userStore.user?.lang]); // eslint-disable-line

  return isLogin ? (
    <Layout>
      <Outlet />
    </Layout>
  ) : (
    <Navigate to="/promo" />
  );
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <PrivateRoutes />,
    children: [
      // { path: "/", index: true, element: <Navigate to="/exercise" /> },
      // comprehension
      // { path: "/exercise", element: <Exercise /> },
      // { path: "/exercise/comprehension/:paperId", element: <EditExercise /> },
      // {
      //   path: "/exercise/comprehension/:attemptId/redo",
      //   element: <EditExercise />,
      // },
      // {
      //   path: "/exercise/comprehension/result/:attemptId",
      //   element: <Results />,
      // },
      // grammar
      // { path: "/exercise/grammar", element: <Exercise /> },
      // { path: "/exercise/grammar/:paperId", element: <EditExercise /> },
      // {
      //   path: "/exercise/grammar/:attemptId/redo",
      //   element: <EditExercise />,
      // },
      // {
      //   path: "/exercise/grammar/result/:attemptId",
      //   element: <Results />,
      // },
      // vocabulary
      // { path: "/exercise/vocabulary/generate", element: <Vocabulary /> },
      // { path: "/exercise/vocabulary", element: <VocabExercise /> },
      // { path: "/exercise/vocabulary/:attemptId", element: <VocabExercise /> },
      // {
      //   path: "/exercise/vocabulary/:redoId/redo",
      //   element: <VocabExercise />,
      // },
      // { path: "/shop", element: <Shop /> },
      // { path: "/history", element: <History /> },
      // { path: "/history/detail/:attemptId", element: <HistoryDetail /> },
      { path: "/profile", element: <Profile /> },
      { path: "/profile/subscription", element: <Subscription /> },
      // { path: "/profile/creditHistory", element: <CreditHistory /> },
      // { path: "/profile/topup", element: <TopUp /> },
      // { path: "/cart", element: <MyCart /> },
      { path: "/subscription/:productId/payment", element: <Payment /> },
      { path: "/topup/:productId/payment", element: <Payment /> },
    ],
  },
  // inse
  // { path: "/inse/:token", element: <INSE /> },
  {
    path: "/login",
    element: <Login />,
  },
  // {
  //   path: "/register",
  //   element: <Register />,
  // },
  {
    path: "/promo",
    element: <Register quickSubscribe={true} />,
  },
  // {
  //   path: "/forgot-password",
  //   element: <ForgotPassword />,
  // },
  // {
  //   path: "/reset-password",
  //   element: <ResetPassword />,
  // },
]);

export default App;
