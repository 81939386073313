import { create } from "zustand";
import http from "../utils/axios";

const useShopStore = create((set, get) => ({
  fetched: false,
  categories: null,
  subcategories: null,
  grades: null,
  subjects: null,
  filterOptions: [],
  purchasedItems: [],

  setCategories: (categories) => set({ categories }),
  setSubcategories: (subcategories) => set({ subcategories }),
  setGrades: (grades) => set({ grades }),

  getCategories: async () => {
    try {
      const res = await http.get("/category?sort_by=order&order_by=asc");
      if (res.error === false) {
        set({ categories: res.data });
      } else {
        throw res;
      }
    } catch (error) {
      console.log(error);
    }
  },

  getSubcategories: async () => {
    try {
      const res = await http.get("/subcategory?sort_by=order&order_by=asc");
      if (res.error === false) {
        set({ subcategories: res.data });
      } else {
        throw res;
      }
    } catch (error) {
      console.log(error);
    }
  },

  getGrades: async () => {
    try {
      const res = await http.get("/grade?sort_by=order&order_by=asc");
      if (res.error === false) {
        set({ grades: res.data });
      } else {
        throw res;
      }
    } catch (error) {
      console.log(error);
    }
  },

  getSubjects: async () => {
    try {
      const res = await http.get("/subject?sort_by=order&order_by=asc");
      if (res.error === false) {
        set({ subjects: res.data });
      } else {
        throw res;
      }
    } catch (error) {
      console.log(error);
    }
  },

  getPurchasedItem: async () => {
    try {
      const res = await http.get("/product/purchased");
      set({ purchasedItems: res || [] });
    } catch (error) {}
  },

  getAll: async () => {
    Promise.all([
      get().getCategories(),
      get().getSubcategories(),
      get().getGrades(),
      get().getSubjects(),
      get().getPurchasedItem(),
    ]).finally(() => {
      const categories = get().categories;
      const subcategories = get().subcategories;
      const grades = get().grades;
      const subjects = get().subjects;

      const filterOptions = [
        {
          type: "subject",
          label: {
            en: "Subject",
            zht: "科目",
            zhs: "科目",
          },
          options: subjects,
        },
        {
          type: "category",
          label: {
            en: "Category",
            zht: "類別",
            zhs: "类别",
          },
          options: categories,
        },
        {
          type: "subcategory",
          label: {
            en: "Subcategory",
            zht: "子類別",
            zhs: "子类别",
          },
          options: subcategories,
        },
        {
          type: "grade",
          label: {
            en: "Grade",
            zht: "年級",
            zhs: "年级",
          },
          options: grades,
        },
      ];

      set({
        filterOptions,
        fetched: true,
      });
    });
  },
  clearAll: () =>
    set({ fetched: false, filterOptions: [], purchasedItems: [] }),
}));

export default useShopStore;
