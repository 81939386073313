import { useState, useEffect } from "react";
import { useUserStore } from "../../stores";
import ReCAPTCHA from "react-google-recaptcha";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import http from "../../utils/axios";
import useToast from "../../utils/toast";
import useYupValidationResolver from "../../utils/yup";
import * as yup from "yup";
import { apiErrorMessage, setLocalStorage } from "../../utils/helpers";
import { useTranslation } from "react-i18next";

import Layout from "../../components/common/Layout";
import FormInput from "../../components/form/Input";
import { Button } from "@mui/material";

import { VideoModal } from "../../components/common/VideoModal";
import { PlayCircle } from "@mui/icons-material";

const Login = () => {
  const [open, setOpen] = useState(false);

  const { t } = useTranslation("common");
  const navigate = useNavigate();
  const toast = useToast();

  // validation form
  const validationSchema = yup.object({
    email: yup.string().email("Invalid Email").required(),
    password: yup.string().required(),
  });
  const resolver = useYupValidationResolver(validationSchema);
  const methods = useForm({ resolver });
  const { handleSubmit } = methods;

  const userState = useUserStore((state) => state);

  const [capVal, setCapVal] = useState(null);

  const onSubmit = async (data) => {
    try {
      const res = await http.post("/auth/local/password", data);
      if (res?.accessToken) {
        const data = {
          access_token: res.accessToken,
          refresh_token: res.refreshToken,
        };
        setLocalStorage(data);
        userState.setUser(data);
      } else {
        throw res;
      }
    } catch (error) {
      const errorMessage = apiErrorMessage(error);
      if (errorMessage) {
        toast.error(errorMessage);
      } else {
        toast.error("Unknown Error");
      }
    }
  };

  useEffect(() => {
    if (userState?.user?.access_token) {
      navigate("/");
    }
    // navigate("/");
  }, [userState?.user?.access_token, navigate]);

  return (
    <>
      <VideoModal
        isOpen={open}
        onClose={() => setOpen(false)}
        videoId="ynhZBSCwX1w"
      />
      <Layout bg="cyber-background">
        <div className="hidden justify-center w-full">
          <div className="bg-white shadow-md rounded-md p-4 w-full sm:py-12 sm:px-16 sm:my-16 sm:w-3/5 relative">
            <Button
              onClick={() => setOpen(true)}
              className="text-3xl absolute right-2 top-2"
            >
              <PlayCircle sx={{ fontSize: 36 }} />{" "}
              <div className="hidden lg:block">{t("header.tutorial-clip")}</div>
            </Button>
            <div className="text-center">
              <div className="text-3xl mb-2">{t("login.login-with-email")}</div>
              <div className="font-light">
                {t("login.no-ac-yet")}
                <Button className="ml-1" onClick={() => navigate("/register")}>
                  {t("login.sign-up-here")}
                </Button>
              </div>
            </div>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="flex flex-col gap-4"
            >
              <FormInput
                methods={methods}
                type="email"
                name="email"
                label={t("email")}
                autoComplete="email"
                required
              />
              <FormInput
                methods={methods}
                name="password"
                label={t("password")}
                type="password"
                required
              />
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                onChange={(value) => setCapVal(value)}
                lang="en"
              />
              <div className="flex flex-col gap-4">
                <Button variant="contained" type="submit" disabled={!capVal}>
                  {t("login.login")}
                </Button>
                <Button
                  type="button"
                  onClick={() => {
                    navigate("/forgot-password");
                  }}
                >
                  {t("login.forgot-password")}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Login;
