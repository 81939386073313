import React, { useState, useImperativeHandle, useEffect } from "react";
import Box from "@mui/material/Box";
import MuiModal from "@mui/material/Modal";

const RawModal = ({ children, title, onCloseFunc }, ref) => {
  const [open, setOpen] = useState(false);

  useImperativeHandle(
    ref,
    () => ({
      handleOpen: handleOpen,
      handleClose: handleClose,
    }),
    [], // eslint-disable-line
  );

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    onCloseFunc && onCloseFunc();
    setOpen(false);
  };

  useEffect(() => {
    if (open) {
      // to disable scroll the background when the modal open
      document.documentElement.style.overflow = "hidden";
    } else {
      // default
      document.documentElement.style.overflow = "visible";
    }
  }, [open]);

  return (
    <MuiModal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableAutoFocus
      disableEnforceFocus
    >
      <Box style={style}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingBottom: "8px",
            fontWeight: "500",
          }}
        >
          <div>{title}</div>
        </Box>
        <Box>{children}</Box>
      </Box>
    </MuiModal>
  );
};

const Modal = React.forwardRef(RawModal);
export default Modal;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 400,
  maxWidth: "100%",
  backgroundColor: "white",
  boxShadow: "0px 0px 10px 2px #3030309c",
  padding: "16px",
  borderRadius: "4px",
};
