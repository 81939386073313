import * as React from "react";
import { FormControl, MenuItem, Select } from "@mui/material";

import i18n from "../../config/i18n";
import { useTranslation } from "react-i18next";

const SwitchLanguageBtn = ({ onUpdate, value }) => {
  const { t } = useTranslation();

  const languages = [
    {
      value: "en",
      label: "English",
    },
    {
      value: "zht",
      label: "繁體中文",
    },
    {
      value: "zhs",
      label: "简体中文",
    },
  ];

  const handleChange = (event) => {
    i18n.changeLanguage(event.target.value);
    onUpdate("lang", event.target.value);
  };

  return (
    <div className="flex items-center justify-between w-[300px]">
      <div className="font-medium text-lg">{t("language")}: </div>
      <div>
        <FormControl fullWidth>
          <Select
            variant="outlined"
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            size="small"
            value={value}
            onChange={handleChange}
          >
            {languages.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </div>
  );
};

export default SwitchLanguageBtn;
