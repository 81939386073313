import React from "react";
import { Controller } from "react-hook-form";
import { Checkbox, TextField } from "@mui/material";
import { Check } from "@mui/icons-material";
import { Link } from "react-router-dom";

const FormInput = ({
  label,
  methods,
  required = false,
  name,
  placeholder,
  textFieldProp,
  type = "text",
  autoComplete = "off",
  link,
  linkText,
}) => {
  const {
    formState: { errors },
    control,
  } = methods;

  return (
    <div className="flex flex-col items-start gap-1">
      {label && (
        <label className="font-medium text-lg capitalize">
          {label}
          {required && (
            <span
              style={
                errors[name]?.type === "required" ||
                errors[name]?.type === "optionality"
                  ? { color: "red" }
                  : { color: "black" }
              }
            >
              {" *"}
            </span>
          )}
          {errors[name] &&
            errors[name].type !== "required" &&
            errors[name].type !== "optionality" && (
              <span className="text-danger ml-3 text-xs">
                {errors[name].message}
              </span>
            )}
        </label>
      )}
      <Controller
        name={name}
        control={control}
        rules={{ required }}
        render={({ field }) => (
          <>
            {type !== "checkbox" ? (
              <TextField
                {...field}
                type={type}
                multiline={
                  type === "password" || type === "email" ? false : true
                }
                size="small"
                fullWidth
                error={!!errors[name]}
                placeholder={placeholder}
                autoComplete={autoComplete}
                value={field.value || ""}
                {...textFieldProp}
              />
            ) : (
              <div className="whitespace-nowrap">
                <Checkbox {...field} checked={field.value} required />{" "}
                {placeholder} <Link to={link}>{linkText}</Link>
              </div>
            )}
          </>
        )}
      />
    </div>
  );
};

export default FormInput;
