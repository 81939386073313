import { useState } from "react";
import { TextField, Button } from "@mui/material";
import { useTranslation } from "react-i18next";

const ProfileInput = ({ label, value = "N/A", onUpdate, disabled, name }) => {
  const [input, setInput] = useState(value);
  const [isEdit, setIsEdit] = useState(false);
  const { t } = useTranslation("profile");

  const onCancel = () => {
    setInput(value);
    setIsEdit(false);
  };

  const onConfirm = () => {
    onUpdate(name, input);
    setIsEdit(false);
  };

  return (
    <div>
      <div className="font-medium text-lg">{label}:</div>
      <div className="flex gap-4">
        <TextField
          value={input}
          onChange={(e) => {
            setInput(e.target.value);
          }}
          disabled={disabled || !isEdit}
          variant="standard"
          sx={{
            minWidth: "300px",
            "& .Mui-disabled.MuiInputBase-input": {
              WebkitTextFillColor: "#7c7c7c",
            },
          }}
        />
        {!disabled && isEdit && (
          <div className="flex gap-6">
            <Button variant="outlined" onClick={onCancel}>
              {t("cancel")}
            </Button>
            <Button variant="contained" onClick={onConfirm}>
              {t("confirm")}
            </Button>
          </div>
        )}

        {!disabled && !isEdit && (
          <Button
            sx={{ fontWeight: 700 }}
            onClick={() => {
              setIsEdit(!isEdit);
            }}
          >
            {t("edit")}
          </Button>
        )}
      </div>
    </div>
  );
};

export default ProfileInput;
