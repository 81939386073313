import Header from "./Header";

const Layout = ({ children, fullWidth = false, bg = "" }) => {
  return (
    <div className={`bg-background text-cusBlack h-full`}>
      <Header />
      <div
        className={`w-full h-full flex justify-center ${bg.length > 0 ? bg : "bg-background"}`}
      >
        <div
          className={`w-full ${fullWidth ? "" : "p-3 sm:px-14 sm:pt-6 sm:pb-24 lg:max-w-screen-lg"}`}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default Layout;
