import React from "react";
import { toast } from "react-toastify";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ClearIcon from "@mui/icons-material/Clear";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const useToast = () => {
  const success = (message) => {
    toast.success(message, {
      icon: (
        <CheckCircleOutlineIcon
          sx={{
            color: "#5BBA59",
          }}
        />
      ),
    });
  };

  const error = (message) => {
    toast.error(message, {
      icon: (
        <ErrorOutlineIcon
          sx={{
            color: "#d32f2f",
          }}
        />
      ),
    });
  };

  return { success, error };
};

export default useToast;
