import { create } from "zustand";
import http from "../utils/axios";
import { useShopStore, useExerciseStore } from "./";

const useUserStore = create((set) => ({
  user: {},
  subscriptionOptions: [],

  setUser: (user) => set({ user }),
  getMe: async () => {
    try {
      const res = await http.get("/me");
      if (res.error === false) {
        set({ user: res.item });
      }
    } catch (error) {
      console.log(error);
    }
  },
  getSubscriptionOptions: async () => {
    try {
      const res = await http.get("/subscription?sort_by=order&order_by=asc");
      if (res.error === false) {
        set({ subscriptionOptions: res.data });
      }
    } catch (error) {
      console.log(error);
    }
  },
  logout: () => {
    useExerciseStore.getState().clearAll();
    useShopStore.getState().clearAll();
    set({ user: {} });
  },
}));

export default useUserStore;
