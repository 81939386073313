import { Button, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useUserStore } from "../../stores";
import { useTranslation } from "react-i18next";

const TopupField = ({ disabled = false }) => {
  const navigate = useNavigate();
  const userStore = useUserStore();
  const { t } = useTranslation("profile");

  return (
    <div className="flex gap-4 flex-col">
      <div className="font-semibold">{t("coin")}</div>
      <div className="flex gap-6">
        <TextField
          value={userStore.user?.coin}
          disabled
          variant="standard"
          sx={{
            minWidth: "300px",
            "& .Mui-disabled.MuiInputBase-input": {
              WebkitTextFillColor: "#7c7c7c",
            },
          }}
        />
        <Button
          variant="contained"
          color="info"
          onClick={() => {
            navigate("/profile/topup");
          }}
          disabled={disabled}
        >
          {t("topup")}
        </Button>
      </div>
    </div>
  );
};

export default TopupField;
