import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import * as yup from "yup";
import http from "../../utils/axios";
import { setLocalStorage } from "../../utils/helpers";
import { useUserStore } from "../../stores";
import { useTranslation } from "react-i18next";

import useYupValidationResolver from "../../utils/yup";
import Layout from "../../components/common/Layout";
import { Button } from "@mui/material";
import FormInput from "../../components/form/Input";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

const Register = ({ quickSubscribe = false }) => {
  const { t } = useTranslation("common");
  const navigate = useNavigate();
  const userState = useUserStore((state) => state);
  const [trialSubscription, setTrialSubscription] = useState(null);

  // validation form
  const validationSchema = yup.object({
    name: yup.string().required(),
    phone: yup
      .string()
      .required()
      .matches(/^[2-9][0-9]{7}$/, "Invalid phone number"),
    email: yup.string().email("Invalid Email").required(),
    password: yup.string().required(),
    confirmPassword: yup
      .string()
      .required()
      .oneOf([yup.ref("password"), null], "Passwords must match"),
  });
  const resolver = useYupValidationResolver(validationSchema);

  const methods = useForm({
    resolver,
  });

  const [capVal, setCapVal] = useState(null);

  const { handleSubmit } = methods;

  const onSubmit = async (data) => {
    try {
      const res = await http.post("/auth/local/register", data);

      setLocalStorage(res);
      userState.setUser(res);
    } catch (error) {
      console.log("error ", error);
    }
  };

  useEffect(() => {
    if (userState?.user?.name) {
      if (quickSubscribe) {
        navigate(`/subscription/${trialSubscription._id}/payment`);
      } else {
        navigate("/");
      }
    }
  }, [userState?.user?.name, navigate]);

  useEffect(() => {
    if (userState.subscriptionOptions.length > 0 && !trialSubscription) {
      const trial = userState.subscriptionOptions.find((item) => item.onceOnly);
      setTrialSubscription(trial);
    }
  }, [userState.subscriptionOptions, trialSubscription]);

  useEffect(() => {
    if (userState.subscriptionOptions.length === 0) {
      userState.getSubscriptionOptions();
    }
  }, []); // eslint-disable-line

  return (
    <Layout>
      <div className="flex justify-center w-full space-x-4">
        <img
          src="promo.jpeg"
          alt="elite learning promotion"
          className="max-w-md sm:py-16 object-scale-down object-top"
        />
        <div className="bg-white shadow-md rounded-md p-4 w-full sm:py-12 sm:px-16 sm:my-16 sm:w-3/5">
          <div>
            <Button className="p-0 capitalize" onClick={() => navigate(-1)}>
              <ChevronLeftIcon className="mr-2" />
              {t("back")}
            </Button>
          </div>
          <div className="text-center">
            <div className="text-3xl mb-2 capitalize">{t("register")}</div>
          </div>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col gap-4"
          >
            <FormInput
              methods={methods}
              name="name"
              label={t("name")}
              required
            />
            <FormInput
              methods={methods}
              name="email"
              label={t("email")}
              required
            />
            <FormInput
              type="password"
              methods={methods}
              name="password"
              label={t("password")}
              required
            />
            <FormInput
              type="password"
              methods={methods}
              name="confirmPassword"
              label={t("login.confirm-password")}
              required
            />
            <FormInput
              methods={methods}
              name="phone"
              label={t("phone")}
              required
            />
            <FormInput
              type="checkbox"
              methods={methods}
              name="consent"
              placeholder={t("login.consent")}
              linkText={t("login.consent-link")}
              link={`${process.env.PUBLIC_URL}/disclaimer.pdf`}
              required
            />
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
              onChange={(value) => setCapVal(value)}
            />
            <div className="flex flex-col">
              <Button
                variant="contained"
                type="submit"
                disabled={!capVal || trialSubscription === null}
                className="capitalize"
              >
                {t("login.register")}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </Layout>
  );
};

export default Register;
