export function processFiles(data, key) {
  let formData = new FormData();
  for (let i = 0; i < data.length; i++) {
    formData.append(key, data[i]);
  }
  return formData;
}

export function setLocalStorage(data) {
  const APP_NAME = process.env.REACT_APP_APP_NAME;
  localStorage.setItem(APP_NAME, JSON.stringify(data));
}

export const getLocalStorage = () => {
  const APP_NAME = process.env.REACT_APP_APP_NAME;
  return JSON.parse(localStorage.getItem(APP_NAME));
};

export const getAccessToken = () => {
  const data = localStorage.getItem(process.env.REACT_APP_APP_NAME);
  return JSON.parse(data)?.access_token;
};

export const getRefreshToken = () => {
  const data = localStorage.getItem(process.env.REACT_APP_APP_NAME);
  return JSON.parse(data)?.refresh_token;
};

export const removeLocalStorage = () => {
  const APP_NAME = process.env.REACT_APP_APP_NAME;
  localStorage.removeItem(APP_NAME);
  return true;
};

export const apiErrorMessage = (res) => {
  return (
    res?.data?.errors[0]?.message?.message || res?.data?.errors[0]?.message
  );
};

export const formAiPayload = ({ qa, article }) => {
  let questions = [];
  let answers = [];
  qa?.forEach((i) => {
    questions.push(i.question);
    answers.push(i.answer);
  });
  return { questions, answers, article };
};

export const formFurtherQuestionPayload = ({ qa, article }) => {
  let questions = [];
  let answers = [];
  let correct_answers = [];
  qa?.forEach((i) => {
    questions.push(i.question);
    answers.push(i.answer);
    correct_answers.push(i.response);
  });
  return {
    questions,
    answers,
    article,
    correct_answers,
  };
};

export const aiResponseToObj = ({ res, qa, ...rest }) => {
  let score = 0;
  let resArray = parseAnswers(res);

  const newQa = qa.map((item, index) => {
    if (resArray[index]?.correct) {
      if (resArray[index]?.partiallyCorrect) {
        score += 0.5;
      } else {
        score++;
      }
    }
    return {
      ...item,
      correct: resArray[index]?.correct || false,
      response: resArray[index].response,
    };
  });

  return {
    qa: newQa,
    total: qa.length,
    score: score,
    perc: (score / qa.length) * 100 || 0,
    ...rest,
  };
};

export const parseAnswers = (str) => {
  const jsonObj = JSON.parse(str);
  const answers = [];

  Object.keys(jsonObj).forEach((key) => {
    const obj = jsonObj[key];
    answers.push({
      correct: obj.correct === 1,
      response: obj.explanation,
      partiallyCorrect: obj.correct === 0.5,
    });
  });

  return answers;
};

export const paperToRaw = ({ article, questions }) => {
  let string = article + "\n";
  questions.forEach((question) => {
    string += question + "\n";
  });
  return string;
};

export function extractQuestions(string) {
  const regex = /\d+\.\s+/g;
  const matches = string.match(regex);
  if (!matches) return [];

  const questions = matches.map((match) => {
    const questionStartIndex = string.indexOf(match);
    const nextQuestionIndex =
      matches.indexOf(match) === matches.length - 1
        ? string.length
        : string.indexOf(matches[matches.indexOf(match) + 1]);

    return string.slice(questionStartIndex, nextQuestionIndex).trim();
  });

  return questions;
}

export function cleanStrings(strings) {
  const cleanedStrings = [];

  strings.forEach((str) => {
    const stringWithoutNumbers = str.replace(/\d+\./g, "");
    const stringWithoutQuotes = stringWithoutNumbers.replace(/"/g, "");
    const stringWithoutDuplicates = stringWithoutQuotes.replace(/_+/g, "_");
    cleanedStrings.push(stringWithoutDuplicates.trim());
  });

  return cleanedStrings;
}

export function processString(inputString) {
  const stringArray = inputString.split("\n");
  const processedArray = [];
  stringArray.forEach((str) => {
    const isWrong = str.includes("Wrong");
    const processedObj = {};

    if (isWrong) {
      processedObj.correct = false;
      const correctAnswerMatch = str.match(/answer: (.+)/i);
      processedObj.answer = correctAnswerMatch
        ? correctAnswerMatch[1].trim()
        : "";
    } else {
      processedObj.correct = true;
    }

    processedArray.push(processedObj);
  });

  return processedArray;
}

export const parseVocabHistory = (qa) => {
  let str = "";
  qa.forEach((i) => {
    str += `${i?.response?.replace(".", "") || i.answer} `;
  });
  return str;
};

export const vocabParser = (str) => {
  const jsonObj = JSON.parse(str);
  const words = [];
  const questions = [];
  const answers = [];

  const exercise = [];
  for (const key in jsonObj) {
    words.push(jsonObj[key].word);

    exercise.push({
      question: jsonObj[key].sentence.replace(/_+/g, "_"),
      answer: jsonObj[key].word,
    });
  }

  // suffle the exercise
  exercise
    .sort(() => Math.random() - 0.5)
    .forEach((item) => {
      questions.push(item.question);
      answers.push(item.answer);
    });

  return { words, questions, answers };
};
