import { useRef, useState } from "react";
import { Button, Box } from "@mui/material";
import moment from "moment";
import Modal from "../common/Modal";
import { Link } from "react-router-dom";

const SubscriptionBox = ({
  item,
  currentPlan,
  expiredDate,
  subscribeOnClick,
  cancelSubscription,
  resumeSubscription,
  disableSubscribe = false,
  subscriptionActive,
  t,
}) => {
  const className = currentPlan
    ? basicClassName + currentClassName
    : basicClassName;

  const modalRef = useRef();

  const [mode, setMode] = useState("cancel");

  const cancelSubscriptionFunc = (id) => {
    cancelSubscription(id);
    modalRef.current.handleClose();
  };

  const resumeSubscriptionFunc = (id) => {
    resumeSubscription(id);
    modalRef.current.handleClose();
  };

  return (
    <div className={className}>
      <div className="flex flex-row gap-4 items-center">
        <div className="font-semibold text-lg">{item.name}</div>
        {currentPlan && (
          <div className="text-primary text-xs">({t("sub.cur")})</div>
        )}
      </div>
      <div className="font-light text-lg">{item.desc}</div>
      <div className="font-light text-lg">
        <span> {t("sub.price")}: </span>
        <span>${item?.price || 0}</span>
      </div>
      <div className="font-light text-lg">
        {t("topup.received")}
        <span>: </span>
        <span>{item?.coin || 0}</span>
      </div>
      {currentPlan && (
        <div className="flex gap-2 items-center font-light text-lg">
          <span> {t("sub.expiry")}: </span>
          <span className="font-medium text-base">
            {expiredDate ? moment(expiredDate).format("DD/MM/YYYY") : ""}
          </span>
        </div>
      )}
      {item?.onceOnly && (
        <div className="font-light text-sm whitespace-nowrap">
          * {t("sub.once")}
          <Link to={`${process.env.PUBLIC_URL}/disclaimer_trial_plan.pdf`}>
            {t("sub.once-disclaimer")}
          </Link>
          {t("sub.once-suffix")}
        </div>
      )}
      {currentPlan && !item.onceOnly && subscriptionActive && (
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            variant="outlined"
            color="error"
            onClick={() => {
              setMode("cancel");
              modalRef.current.handleOpen();
            }}
            sx={{}}
          >
            {t("sub.pause")}
          </Button>
        </Box>
      )}
      <div>
        {currentPlan && !item.onceOnly && !subscriptionActive && (
          <Button
            variant="contained"
            fullWidth
            onClick={() => {
              setMode("resume");
              modalRef.current.handleOpen();
            }}
          >
            {t("sub.resume")}
          </Button>
        )}
        {!currentPlan && (
          <Button
            variant="contained"
            fullWidth
            disabled={disableSubscribe}
            onClick={() => subscribeOnClick(item._id)}
          >
            {t("sub.subscribe")}
          </Button>
        )}
      </div>
      <Modal
        ref={modalRef}
        title={
          mode === "cancel" ? "Cancel Subscription" : "Resume Subscription"
        }
      >
        <Box>
          <Box sx={{ marginTop: 2, marginBottom: 4 }}>
            {mode === "cancel"
              ? "Are you sure you want to pause your subscription?"
              : "Are you sure you want to resume your subscription?"}
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              gap: 2,
            }}
          >
            <Button
              variant="outlined"
              onClick={() => {
                modalRef.current.handleClose();
              }}
            >
              {t("sub.cancel")}
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                if (mode === "resume") resumeSubscriptionFunc(item._id);
                if (mode === "cancel") cancelSubscriptionFunc(item._id);
              }}
            >
              {t("sub.confirm")}
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default SubscriptionBox;

const currentClassName = " border-2 border-solid border-primary";
const basicClassName = "bg-white p-6 shadow-md rounded-lg flex flex-col gap-3";
