import { create } from "zustand";

const useExerciseStore = create((set) => ({
  vocabQuestions: [],
  vocabOptions: [],
  vocabAnswers: [],

  setVocabQuestion: (vocabQuestions) => set({ vocabQuestions }),
  setVocabOptions: (vocabOptions) => set({ vocabOptions }),
  setVocabAnswers: (vocabAnswers) => set({ vocabAnswers }),
  clearAll: () =>
    set({ vocabQuestions: [], vocabOptions: [], vocabAnswers: [] }),
}));

export default useExerciseStore;
