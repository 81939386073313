import React from "react";
import ModalVideo from "react-modal-video";
import "react-modal-video/scss/modal-video.scss";

export const VideoModal = ({ isOpen, onClose, videoId }) => {
  return (
    <div>
      <ModalVideo
        channel="youtube"
        isOpen={isOpen}
        videoId={videoId}
        onClose={onClose}
      />
    </div>
  );
};
