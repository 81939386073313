import { createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: { main: "#005b96" },
    secondary: { main: "#b3cde0" },
  },
  components: {
    MuiToggleButton: {
      styleOverrides: {
        root: {
          color: "black",
          textTransform: "none",
          "&.Mui-selected": {
            color: "black",
            backgroundColor: "#b3cde0",
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
  },
});

export default theme;
